@media(min-width: 1024px) {
  .dropdown {
    display: none;
  }
  .btnGroup {
    display: inline-block;
  }
}

@media(max-width: 1024px) {
  .dropdown {
    display: inline-block;
  }
  .btnGroup {
    display: none !important;
  }
}