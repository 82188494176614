@media(min-width: 576px) {
    .modalDialog{
        max-width: 750px !important;
    }
}

.totalCount {
    font-size: 1rem;
    color: #666;
    margin-left: 1rem;
    font-weight: normal;
}
