@media(min-width: 576px) {
  .modalDialog{
    max-width: 750px !important;
  }
}

@media print{
  .modalDialog{
    max-width: none !important;
    width: 100%;
    margin: 0 !important;
    position: absolute;
    top: 0px;
  }
}

.backgroundModal {
  max-width: 90% !important;
  margin-top: 10px !important;
}

@media print {
  .backgroundModal {
    max-width: none !important;
    width: 100%;
    margin: 0 !important;
    position: absolute;
    top: 0px;
  }
}

.backgroundModalBody {
  padding: 0 !important;
}

.attribute{
  /*border: solid 1px #aaa;*/
  border-radius: 8px;
  line-height: 2.8em;
  background-color: #fafafa;
  box-shadow: inset -2px 2px 10px 0 #aaa;
  padding: 3px 16px 0px 16px;
  white-space: nowrap;
  margin: 0px 8px 8px 0px;
}

.label{
  font-size: 1.1em;
  margin-right: 0.5em;
  font-weight: bold;
}

.value{
  font-size: 1.5em;
  font-weight: bold;
}

.unit{
  font-size: 0.8em;
}

.details{
  font-size: 0.9em;
  padding-top: 3px;
  margin: 0px 0px 0px 16px;
  white-space: nowrap;
}

.details strong{
  margin-right: 8px;
}

.rangeWarning{
  color: #ffc107;
  font-size: 1.5em;
  margin-left: 6px;
}

.rangeWarning:hover{
  cursor: pointer;
}

.datePicker{
  width: 130px;
  cursor: pointer;
}

.datePickerWithTime{
  width: 170px;
  cursor: pointer;
  display: inline-block;
}

@media(max-width: 576px) {
  .datePicker {
    width: 100px;
  }

  .datePickerWithTime{
    width: 140px;
  }
}

.timeSpanDropdownToggle{
  font-size: 0.85rem !important;
  padding: 0.25rem 0.75rem;
}

.datePicker input{
  font-size: 0.85rem;
  cursor: pointer;
  background-image: linear-gradient(#6b6161, #403737);
  color: #ccc !important;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  border: solid 1px #343a40;
  height: auto;
  padding: 0.25rem 0.75rem;
}

.datePicker input:hover{
  color: #fff !important;
}

.spinner{
  margin-left: 10px;
  margin-right: 10px;
}

.icon{
  height: 48px;
  margin-right: 8px;
  display: inline-block;
  margin-top: -5px;
}

@media(max-width: 576px) {
  .icon{
    height: 40px;
  }
}

.childIcon{
  height: 40px;
  margin-right: 8px;
  margin-left: -8px;
  display: inline-block;
  margin-top: -3px;
}

@media(max-width: 576px) {
  .childIcon{
    height: 36px;
  }
}

.valueLabel{
  float: right;
  font-size: 0.95em;
  /*color: #fff;*/
  padding: 5px 8px 5px 8px;
  /*text-shadow: 1px 1px 1px #000, -1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000;*/
  /*background: #746767;*/
  background-color: #fafafa;
  box-shadow: inset -2px 2px 10px 0 #bbb;
  border-radius: 5px;
  min-width: 70px;
  text-align: center;
  font-weight: bold;
  margin-top: 2px;
}

.valueLabelSeparator{
  border-right: solid 1px #333;
  margin: 0px 6px 0px 6px;
}

.valueLabelUnit{
  font-size: 0.75em;
  padding-left: 2px;
}

.markerLabelWarning{
  color: #ffc107;
  padding-left: 8px;
  font-size: 1.45em;
  padding-top: 4px;
}

.mapContainer{
  clear: both;
  padding-top: 6px;
  transition: height .25s;
  height: 0px;
  overflow: hidden;
}

.mapContainerVisible{
  height: 370px;
  padding-bottom: 6px;
}

.maxResultReached{
  color: #f30;
  margin-top: 10px;
  font-weight: bold;
  display: block;
}

.timeFilterItem{
  padding: 0px 6px 6px 0px !important;
}

.instrumentRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.instrumentRowValueLabel{
  font-size: 0.95em;
  padding: 5px 8px 5px 8px;
  box-shadow: inset -2px 2px 10px 0 #bbb;
  border-radius: 5px;
  min-width: 70px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
}

.oldDataIcon{
  margin-left: 6px;
  color: #ff6600;
  font-size: 0.9em;
}

.offlineIcon{
  margin-left: 6px;
  color: #5a7076;
  font-size: 0.9em;
}

.avgCheckBox{
  font-size: 0.8em;
}