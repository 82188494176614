@font-face {
  font-family: 'Righteous';
  src: local('Righteous'), url(./fonts/Righteous-Regular.ttf) format('truetype');
}

html, body{
  height: 100%;
}

@media(max-width: 768px) {
  html{
    font-size: 14px;
  }
}

@media(max-width: 576px) {
  html{
    font-size: 13px;
  }
}

@media print{
  body{
    background-color: #fff !important;
    background-image: none !important;
  }
}

#root {
  margin-top: 20px;
  margin-left: 3%;
  margin-right: 3%;
  padding-bottom: 36px;
}

@media(max-width: 768px) {
  #root{
    margin: 0;
  }
  .container-fluid{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

table.table, .table-responsive{
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: #fafafa;
  margin-bottom: 0px;
}

.table{
  font-size: 0.9em;
}

table tr.clickable{
  cursor: pointer;
}

table tr.disabled td{
  color: #999;
}

table tr.emphasize td{
  font-weight: bold;
}

table th.sortable{
  cursor: pointer;
  color: #591c0b;
}

table th.sortable:hover{
  color: #a9272e;
}

.table td .btn{
  margin: 0;
  padding: 0px 0px 0px 10px;
  color: #7c200b;
 /* box-shadow: 0px 0px 8px #ff5f0d;*/
}

.table td .btn:hover {
  color: #d42c38;
}

.table td .btn:disabled {
  color: #6c757d;
}

.table .actions {
  text-align: right;
  width: 100px;
  white-space: nowrap;
}

.btn-toolbar{
  margin-bottom: 10px;
}

.btn.btn-primary{
  background-image: linear-gradient(#6799dd, #3c719c);
  border-color: #395881;
  color: #eee;
  text-shadow: -1px 0 #025, 0 1px #025, 1px 0 #025, 0 -1px #025;
  /*box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);*/
}

.btn.btn-primary:hover{
  background-image: linear-gradient(#5772ad, #3c5a85);
  color: #fff !important;
}

.btn.btn-link-danger svg{
  color: #f30;
}

.btn.btn-link-danger:hover svg{
  color: #c02700;
}

.popover{
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.5);
}

.world-map-component{
  height: 400px;
  margin-bottom: 6px;
}

.modal-content{
  /*box-shadow: 0 0px 32px 0 rgba(255, 95, 13, 0.4);*/
  box-shadow: 0 0px 32px 0 rgba(0, 0, 0, 0.5);
  background: transparent !important;
}

.modal-body{
  background-color: #fff;
}

.modal-header{
  background-color: #000;
  /*background-image: linear-gradient(to right, #400 , #800);*/
  background-image: linear-gradient(to left, #6b6161, #403737);
  box-shadow: inset 10px -20px 20px 0 rgba(0, 0, 0, 0.2), inset -5px 10px 10px 0 rgba(200, 200, 200, 0.2);
  color: #fff;
  /*text-shadow: 0px 0px 8px #ff5f0d;*/
  text-shadow: 0px 0px 8px #000;
  max-height: 70px;
  /*border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;*/
  /*border: solid 1px #300;*/
}

.modal-footer{
  background-color: #eee;
}

@media(max-width: 576px) {
  .hide-for-sm{
    display: none;
  }
}

svg.hilite{
  color: #ffa;
}

.resource-list-pagination-component{
  margin-top: 15px;
}

.resource-list-pagination-component .total-record-count{
  float: right;
  color: #888;
  font-size: 0.85em;
}

.resources-loading-spinner{
  position: fixed;
  bottom: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.35);
  padding: 10px;
  border-radius: 10px;
  z-index: 100000;
}

div.rdtPicker{
  /*right: 0px;*/ /* Align picker to the right side of the input */
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.5) !important;
  z-index: 1000001 !important;
}

.dropdown-menu.show{
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.5) !important;
}

#websocketError{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 10px 10px 10px;
  background-color: #d00;
  color: #fff;
  z-index: 10000;
  text-align: center;
}

#websocketError .btn{
  vertical-align: bottom;
  padding: 0;
  color: #fff;
  font-weight: bold;
  display: inline;
}

@media print {
  .modal {
    position: absolute !important;
    top: 0px !important;
  }

  body.modal-open #root {
    display: none;
  }

  .modal-header button {
    display: none !important;
  }

  .modal-backdrop.show {
    opacity: 1 !important;
    background: #fff !important;
  }

  .modal-footer {
    display: none !important;
  }

  .hide-from-print {
    display: none !important;
  }

  #websocketError {
    display: none;
  }
}

.modal-body .fullscreen-enabled {
  padding: 2%;
  background-color: #fff;
}

.exit-full-screen-button {
  display: none !important;
}

.fullscreen-enabled .exit-full-screen-button{
  display: block !important;
  top: 0;
  right: 0;
  position: absolute;
  margin: 2%;
  z-index: 10000;
}
