.container{
  margin-bottom: 12px;
  padding-bottom: 5px;
  border-bottom: solid 1px #ccc;
}

.containerHover{
  padding-left: 10px;
  position: absolute;
  z-index: 1000;
  width: 100%;
  /*background: rgba(255,255,255,0.3);*/
}

.item{
  font-weight: bold;
  font-size: 1.0em;
  line-height: 1.9em;
}

.item button{
  font-weight: bold;
  font-size: 1.0em;
  padding: 0;
  margin-bottom: 4px;
}

.item button{
  display: inline !important;
}

.item a, .item button{
  color: #591c0b !important;
}

.item a:hover, .item button:hover{
  color: #a9272e !important;
  text-decoration: none !important;
}

.containerHover button, .containerHover a, .containerHover span {
  text-shadow: 1px 1px 1px #fff, -1px 1px 1px #fff, -1px -1px 1px #fff, 1px -1px 1px #fff !important;
}


.currentItem{
  font-size: 1.1em;
}

.separator {
  margin: 0px 6px 0px 6px;
  color: #666;
}

.wrapper{

}

.hoverText {
  text-shadow: 1px 1px 1px #fff, -1px 1px 1px #fff, -1px -1px 1px #fff, 1px -1px 1px #fff;
}

@media(max-width: 576px){
  .wrapper{
    display: block;
  }

  .container{
    margin-bottom: 6px;
  }
}