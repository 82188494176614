.content{
  padding: 16px;
  background-color: #e6e6e6;
  min-height: 500px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-image: linear-gradient(to right, rgba(230,230,230,1), rgba(210,210,210,1));
  box-shadow: inset 2px 0px 2px 0 rgba(0, 0, 0, 0.2), inset -2px 2px 2px 0 rgba(255, 255, 255, 0.5);
}

@media(max-width: 576px) {
  .content{
    padding: 8px;
  }
}
