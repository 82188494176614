@media(min-width: 576px) {
  .modalDialog{
    max-width: 580px !important;
  }
}

.worldMapContainer{
  transition: height .25s;
  height: 0px;
  overflow: hidden;
}

.worldMapContainerVisible{
  height: 425px;
  margin-bottom: 6px;
}

.worldMap{
  height: 325px;
  margin-bottom: 6px
}

.offRangeTrigger>div{
  padding-right: 5px !important;
}