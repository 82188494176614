.alert{
  position: -webkit-sticky !important; /* Safari */
  position: sticky !important;
  top: 0px;
  height: 0px;
  padding: 0px;
  overflow: hidden;
  transition: height 1.5s, top 1.5s, padding 1.5s;
}

.alertVisible{
  top: 10px;
  height: 70px;
  padding: 8px;
  transition: height 0.5s, top 0.5s, padding 0.5s;
}

.alert div{
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.5);
}
