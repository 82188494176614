.infoCard{
  margin-top: 16px;
  box-shadow: 0 0px 16px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 16px;
  background-color: #fafafa;
}

.icon{
  width: 32px;
  display: inline-block;
  margin-top: -4px;
  margin-left: -4px;
  margin-right: 6px;
}