.container{
  /*background-color: rgba(255, 255, 255, 0.75);*/
  /*padding: 10px 10px 5px 10px;*/
  /*border-radius: 8px;*/
  margin-bottom: 4px;
}

@media(max-width: 768px) {
  .container{
    max-width: 400px;
  }
}

@media(max-width: 576px) {
  .container{
    max-width: 300px;
  }
}

.container select, .container input{
  min-width: 250px;
  border: solid 1px #999;
  font-size: 0.9em;
}

.container label{
  font-weight: bold;
  font-size: 0.9em;
}

.container ul a{
  font-size: 0.9em;
}