@media(min-width: 576px) {
  .modalDialog{
    max-width: 580px !important;
  }
}

.worldMap{
  transition: height .25s;
  height: 0px;
  overflow: hidden;
}

.worldMapVisible{
  height: 400px;
  margin-bottom: 8px;
}

.mapButton{
  margin-left: 8px;
  font-size: 1em;
  padding: 2px 8px 2px 8px !important;
}
