.container{
  height: 36px;
  line-height: 36px;
  text-align: right;
  font-size: 0.8em;
  background-color: rgba(210,210,210,1);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #888;
  padding-right: 24px;
}

.container a:link,
.container a:active,
.container a:visited{
  color: #777;
}

.container a:hover{
  color: #666;
  text-decoration: underline;
}