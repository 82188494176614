.deviceActivityReport{
  display: flex;
  flex-direction: row;
  border: solid 1px #dee2e6;
  width: fit-content;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: #fafafa;
}

.deviceActivityReportItem{
  font-size: 0.8em;
  border-right: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
}

.deviceActivityReportItemDate{
  font-weight: bold;
  padding: 6px 10px 6px 10px;
  background-color: #fff;
  white-space: nowrap;
  border-right: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
}

.deviceActivityReportItemValue{
  padding: 6px 10px 6px 10px;
  background-color: #eee;
}

.deviceActivityReportItemValueOdd{
  padding: 6px 10px 6px 10px;
  background-color: #f4f4f4;
}

@media(max-width: 1024px) {
  .deviceActivityReport{
    flex-direction: column;
  }

  .deviceActivityReportItemDate{
    min-width: 100px;
    border-bottom: none;
  }

  .deviceActivityReportItemValue, .deviceActivityReportItemValueOdd{
    min-width: 40px;
  }

  .deviceActivityReportItemDate, .deviceActivityReportItemValue, .deviceActivityReportItemValueOdd{
    display: inline-block;
  }
}
