.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 512px;
  height: 512px;
}

.marker {
  position: absolute;
}

.markerContent {
  position: relative;
}

.markerImage {
  position: absolute;
  width: 32px;
  height: 32px;
  top: -32px;
  left: -16px;
  cursor: pointer;
}

.markerInstrumentName {
  position: absolute;
  background: rgba(0,0,0,0.6);
  color: #fff;
  font-size: 0.7rem;
  padding: 1px 4px 1px 4px;
  border-radius: 4px;
  white-space: nowrap;
  transform: translateX(-50%);
  top: 4px;
  text-shadow: 1px 1px 1px #000, -1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000;
}

.markerLabelWarning{
  color: #ffef00;
  padding-left: 4px;
  font-size: 15px;
}

.markerLabelBatteryWarning{
  color: #ff1100;
  padding-left: 4px;
  font-size: 16px;
}

.markerLabelContainer{
  position: absolute;
  bottom: 0px;
  left: 16px;
}

.markerLabel{
  background: rgba(0,0,0,0.6);
  color: #fff;
  font-size: 0.7rem;
  padding: 1px 4px 1px 4px;
  border-radius: 4px;
  white-space: nowrap;
  margin-top: 3px;
}

.markerLabelValue{
  white-space: nowrap;
  font-weight: bold;
  font-size: 0.7rem;
  text-shadow: 1px 1px 1px #000, -1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000;
}

.markerLabelUnit{
  padding-left: 3px;
  font-size: 0.6rem;
  text-shadow: 1px 1px 1px #000, -1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000;
}

