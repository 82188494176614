.datePicker {
  display: inline-block;
}

.datePicker input {
  background-color: white !important;
  min-width: 0 !important;
  border-right: none !important;
}

.datePicker button {
  padding: 0 0.5rem !important;
  border-radius: 0 4px 4px 0 !important;
  border-left: none !important;
  background-color: white !important;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #888;
  text-shadow: 0 1px 0 #fff;
  border-color: #999;
}

.datePicker button:hover {
  color: #000;
  border-color: #999;
}
