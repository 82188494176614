.header {
  /*background: transparent;*/
  /*background-image: linear-gradient(to right, #100 , #600);*/
  /*background-image: linear-gradient(to right, #300, #800);*/
  padding: 20px;
  box-shadow: inset 0px -6px 16px 0 rgba(0, 0, 0, 0.4), inset -6px 6px 16px 0 rgba(200, 200, 200, 0.25);
  position: relative;
  background-size: cover;
  /*background-attachment: fixed;*/
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  height: 80px;
  background-image: url('../../../images/2kBack.jpg');
}

.title {
  color: #fff;
  font-weight: bold;
  font-size: 1.75em;
  line-height: 1.5em;
  text-shadow: 0px 0px 8px #000;
  position: relative;
  left: 90px;
}

.logo {
  position: absolute;
  height: 54px;
  width: 70px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 16px;
  margin-top: -6px;
  background-color: #222;
}

.logo img {
  /*
  height: 32px;
  width: 40px;
  margin: 12px 0px 0px 14px;*/
  height: 100%;
  width: 100%;
}

@media(max-width: 576px) {
  .header{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 70px;
  }

  .title{
    font-size: 1.5em;
    top: 5px;
    left: 70px;
    top: 0px;
  }

  .logo{
    height: 42px;
    width: 55px;
  }
}