.markerLabel{
  z-index: 1000 !important;
  /*margin-top: 3px !important;
  margin-left: 3px !important;*/
  font-size: 12px;
  color: #fff;
  padding: 2px 4px 2px 4px;
  text-shadow: 1px 1px 1px #000, -1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000;
  background: rgba(0,0,0,0.3);
  border-radius: 3px;
}

.labelValue{
  white-space: nowrap;
}

.labelUnit{
  font-size: 10px;
  padding-left: 2px;
}

.markerLabelWarning{
  color: #ffef00;
  padding-left: 4px;
  font-size: 15px;
}

.markerLabelBatteryWarning{
  color: #ff1100;
  padding-left: 4px;
  font-size: 16px;
}

.alarmedExistSymbol{
  color: #ffc107;
  font-size: 1.8em;
  /*float: right;*/
  /*background: rgba(0, 0, 0, 0.35);*/
  padding-right: 7px;
  vertical-align: middle;
  /*border-radius: 5px;
  height: 35px;
  width: 35px;*/
}

.emptyResultNotification{
  position: absolute;
  bottom: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.35);
  padding: 10px;
  border-radius: 10px;
  z-index: 100000;
}