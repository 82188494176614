.container{
  margin-left: 6px;
  /*border-top: solid 1px #ccc;*/
  font-size: 0.95em;
  /*min-width: 340px;*/
  max-width: 500px;
}

.containerLevel1{
  box-shadow: 0 0px 16px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  margin-bottom: 20px;
  margin-left: 0px;
  border-top: none;
}

@media(max-width: 576px) {
  .containerLevel1{
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
  }
}

.containerLevel1:hover {
  box-shadow: 0 0px 12px 0 rgba(210, 20, 0, 0.25);
}

.name{
  /*max-width: 250px;*/
  /*white-space: nowrap;
  overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  display: inline-block;
  /*padding-top: 4px;*/
}

.icon{
  width: 32px;
  margin-right: 4px;
  margin-left: -4px;
  display: inline-block;
  vertical-align: top;
}

.childIcon{
  width: 28px;
  margin-right: 4px;
  margin-left: 4px;
  margin-bottom: 3px;
}

.groupIcon{
  display: none;
}

.valueLabel{
  font-size: 0.95em;
  /*color: #fff;*/
  padding: 5px 8px 5px 8px;
  /*text-shadow: 1px 1px 1px #000, -1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000;*/
  /*background: #746767;*/
  background-color: #f0f0f0;
  box-shadow: inset -2px 2px 10px 0 #bbb;
  border-radius: 5px;
  min-width: 70px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
}

.valueLabelSeparator{
  border-right: solid 1px #333;
  margin: 0px 6px 0px 6px;
}

.valueLabelUnit{
  font-size: 0.75em;
  padding-left: 2px;
}

.markerLabelPoorSignal{
  color: #ff4800;
  font-size: 0.9em;
  margin-right: 6px;
}

.markerLabelBatteryWarning{
  color: #ff1100;
  font-size: 0.9em;
  margin-right: 6px;
}

.markerLabelOldDataWarning{
  color: #ff6600;
  font-size: 0.9em;
  margin-right: 6px;
}

.markerLabelOffline{
  color: #5a7076;
  font-size: 0.9em;
  margin-right: 6px;
}

@keyframes pulse {
  0% {
    transform: scale(0.9, 0.9);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(0.9, 0.9);
  }
}

.markerLabelWarning{
  color: #ffc107;
  font-size: 1.1em;
  margin-right: 6px;
  animation: pulse 1s linear infinite;
}

/* WidgetRow styles */
.root, .rootGroup, .group, .child{
  padding: 6px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.root:hover, .rootGroup:hover, .group:hover, .child:hover{
  background-color: #e6e6e6;
  background-image: none !important;
}

.rootGroup{
  font-weight: bold;
  border-bottom: solid 1px #cfcfcf;
}

.group{
  magin-bottom: 4px;
  font-weight: bold;
  border-bottom: solid 1px #cfcfcf;
}

.child{
  /*border-top: solid 1px #cfcfcf;*/
  cursor: pointer;
}

.oddChild{
  background-color: #e6e6e6;
  background-image: linear-gradient(to right, #eaeaea, #f0f0f0);
}

.iconAndName, .warningIconsAndBadge{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}

.iconAndName{
  justify-content: flex-start;
}

.warningIconsAndBadge{
  justify-content: flex-end;
}

.spinner{
  width: 1.4rem !important;
  height: 1.4rem !important;
  border-left-color: #ccc !important;
  border-top-color: #ccc !important;
  border-bottom-color: #ccc !important;
}

.description {
  font-size: 0.8rem;
  margin: 8px 6px 8px 6px;
}